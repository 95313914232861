<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <span class="brand-logo">
          <vuexy-logo />
        </span>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Добро пожаловать 👋
          </b-card-title>
          <b-card-text class="mb-2">
            в Very Berry LAB
          </b-card-text>

          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Пожалуйста введите свой логин и пароль для входа в аккаунт</span></small>
              </p>
            </div>
          </b-alert>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Номер телефона"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Номер телефона"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="phone"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    v-mask="phoneMask"
                    placeholder="+7(111) 111 11 11"
                  />
                  <small class="text-danger">{{ errors.length > 0 ? "Обязательное поле" : ""}}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Пароль</label>
                  <!-- <b-link :to="{name:'auth-forgot-password'}">
                    <small>Забыли пароль?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Пароль"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{errors.length > 0 ? "Обязательное поле" : ""}}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Запомнить меня
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Войти
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { phoneMask } from '@/config';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { RootNameSpace, RootMutationTypes } from '@/store/types';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      phone: '',
      sideImg: require('@/assets/images/pages/login-very-berry.jpg'),

      // validation rules
      required,
      email,
      phoneMask,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            phone: this.phone.replace(/[^\d]/g, ''),
            password: this.password,
          }).then(response => {
            const userData = response.data.data;

            userData.ability = [
              {
                action: 'manage',
                subject: 'all',
              },
            ];

            this.$store.state.auth.userData = userData

            useJwt.setToken(userData.token)
            useJwt.setRefreshToken(userData.token)
            localStorage.setItem('userData', JSON.stringify(userData))


            this.$ability.update(userData.ability)

            // ? This is just for demo purpose as well.
            // ? Because we are showing eCommerce app's cart items count in navbar
            // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Добро пожаловать ${userData.full_name || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Вы успешно вошли в систему ${userData.role}.`,
                    },
                  })
                })
          }) .catch(error => {
            this.$refs.loginForm.setErrors(error)

            const message = 'Неверный логин или пароль, попробуйте еще раз';
            this.$store.commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' });
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/_variables';
@import '@core/scss/base/components/_variables-dark.scss';
@import '@assets/scss/mixins';

.dark-layout {
    .brand-logo {
        @include media992max {
            background-color: $theme-dark-card-bg !important;
        }
    }
}

.brand-logo {
    img {
        width: 200px;
    }
    @include media992max {
        position: static !important;
        padding: 20px 0 !important;
        display: flex;
        width: 100%;
        background-color: $white;
        img {
            width: 120px;
        }
    }
}
</style>
